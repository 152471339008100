import get from 'lodash/fp/get';

import { State } from '../../types';
import { Poi } from './types';

const BASE = 'app';

export const getSessionExpiredAcknowledged: (state: State) => boolean = get(`${BASE}.sessionExpiredAcknowledged`);

export const getFetchPoiRequested: (state: State) => boolean = get(`${BASE}.fetchPoiRequested`);
export const getFetchPoiFailed: (state: State) => boolean = get(`${BASE}.fetchPoiFailed`);
export const getPoi: (state: State) => Poi | undefined = get(`${BASE}.poi`);
export const getSelectedPoiIds: (state: State) => string[] = get(`${BASE}.selectedPoiIds`);
export const getWidgetId = (state: State): string | undefined => state.app.widgetId;
export const getIsWidgetVisible = (state: State): boolean => state.app.visible;
