import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { State } from '../../types';
import { config } from '../../config';
import { DEFAULT_LOCALE, getIdToken, getLanguageData, getLocale, isUserSessionExpired } from '../../configuration';
import { getSessionExpiredAcknowledged } from './selector';
import MessageHandlerContainer, { sendMessage } from './MessageHandler';
import PoiDetailsContainer from './poi/PoiDetailsContainer';
import { widgetLoadedType } from './remoteActionTypes';

const App = (props: Props) => {
    const { languageData, userLocale } = props;

    useEffect(() => {
        sendMessage({ type: widgetLoadedType });
    }, []);

    return (
        <ApplicationLayout>
            <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={languageData}>
                <NotificationsContainer stacked={true} />
                <MessageHandlerContainer />
                <Routes>
                    <Route path="poiDetails" element={<PoiDetailsContainer />} />
                    <Route path="/" element={<Navigate to="/poiDetails" />} />
                </Routes>
            </IntlProvider>
        </ApplicationLayout>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    idToken: getIdToken(state),
    homeRoute: config.homeRoute as string,
    languageData: getLanguageData(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLocale(state),
});

const connector = connect(mapStateToProps, {});
const AppContainer = connector(App);
export default AppContainer;
