import React from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Notification from '@rio-cloud/rio-uikit/Notification';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import flow from 'lodash/fp/flow';
import replace from 'lodash/fp/replace';

import { State } from '../../../types';
import { PoiType } from '../types';
import { getFetchPoiRequested, getPoi } from '../selector';
import PoiDetailsRow from './PoiDetailsRow';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { propagateEditCustomerPoi } from '../thunks/propagateToParent';

const WRAPPER_CLASSES = 'position-fixed display-flex flex-column justify-content-center width-100pct height-100pct';

const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);
};

export const PoiDetails = React.memo(({ poi, fetchPoiRequested, editCustomerPoi }: Props) => {
    const intl = useIntl();

    if (!poi) {
        return null;
    }

    if (fetchPoiRequested) {
        return (
            <div className={WRAPPER_CLASSES} data-testid={'PoiDetailsSpinner'}>
                {<Spinner text={intl.formatMessage({ id: 'intl-msg:details.loading' })} />}
            </div>
        );
    }

    const classes = 'PoiDetails panel panel-body panel-default padding-0 overflow-hidden';

    const isCustomerPoi = poi.type === PoiType.Customer;

    const poiLabelIcon = isCustomerPoi ? 'pushpin' : 'workshop';

    const optionalEditButtonIcon = isCustomerPoi ? 'pencil' : undefined;

    const editCustomerPoiHandler = () => {
        editCustomerPoi(poi.id);
        // window.top.location.href = `https://geofencing.rio.cloud/pois/edit/${poi.id}`;

        gaPush({
            category: TRACKING_CATEGORIES.POI_DETAILS,
            action: 'Edit CustomerPoi Clicked',
            label: 'Clicked Edit CustomerPoi',
        });
    };

    const handleEditPoi = isCustomerPoi ? editCustomerPoiHandler : undefined;

    const coordinates = `${poi.coordinates.latitude.toFixed(5)}, ${poi.coordinates.longitude.toFixed(5)}`;

    const handleCopyCoordinatesButton = () => {
        copyToClipboard(coordinates);
        Notification.info(intl.formatMessage({ id: 'intl-msg:details.copyToClipboardInfo' }), coordinates);
        gaPush({
            category: TRACKING_CATEGORIES.POI_DETAILS,
            action: 'Copy Coordinates to Clipboard Clicked',
            label: 'Clicked Copy Coordinates to Clipboard',
        });
    };

    const handleCopyMailButton = () => {
        if (!contact?.email) {
            return;
        }
        copyToClipboard(contact.email);
        Notification.info(intl.formatMessage({ id: 'intl-msg:details.copyToClipboardInfo' }), contact.email);
        gaPush({
            category: TRACKING_CATEGORIES.POI_DETAILS,
            action: 'Copy Mail to Clipboard Clicked',
            label: 'Clicked Copy Mail to Clipboard',
        });
    };

    const handleCopyPhoneButton = () => {
        if (!contact?.phone) {
            return;
        }
        copyToClipboard(contact.phone);
        Notification.info(intl.formatMessage({ id: 'intl-msg:details.copyToClipboardInfo' }), contact.phone);
        gaPush({
            category: TRACKING_CATEGORIES.POI_DETAILS,
            action: 'Copy Phone Number to Clipboard Clicked',
            label: 'Clicked Copy Phone Number to Clipboard',
        });
    };

    const convertToPhoneNumber = (tel: string = '') =>
        flow(replace(/\s/g, ''), replace(/-/g, ''), replace('(', ''), replace(')', ''))(tel);

    const { id, name, address, category, contact } = poi;

    return (
        <div className={classes} data-testid={id}>
            <div className={'margin-bottom--1'}>
                <PoiDetailsRow
                    labelIcon={poiLabelIcon}
                    labelIntlId={'intl-msg:details.caption'}
                    text={name}
                    buttonIcon={optionalEditButtonIcon}
                    onButtonClick={handleEditPoi}
                />
                <PoiDetailsRow labelIntlId={'intl-msg:details.address'} text={address} />
                <PoiDetailsRow
                    labelIntlId={'intl-msg:details.coordinates'}
                    text={coordinates}
                    buttonIcon={'duplicate'}
                    onButtonClick={handleCopyCoordinatesButton}
                />
                {category && (
                    <PoiDetailsRow
                        labelIntlId={'intl-msg:details.category'}
                        text={<span className={'label label-default label-condensed'}>{category}</span>}
                    />
                )}
                <PoiDetailsRow
                    labelIntlId={'intl-msg:details.mail'}
                    text={contact?.email}
                    link={`mailto:${contact?.email}`}
                    buttonIcon={'duplicate'}
                    onButtonClick={handleCopyMailButton}
                />
                <PoiDetailsRow
                    labelIntlId={'intl-msg:details.phone'}
                    text={contact?.phone}
                    link={`tel:${convertToPhoneNumber(contact?.phone)}`}
                    buttonIcon={'duplicate'}
                    onButtonClick={handleCopyPhoneButton}
                />
            </div>
        </div>
    );
});

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    poi: getPoi(state),
    fetchPoiRequested: getFetchPoiRequested(state),
});
const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, AnyAction>) => ({
    editCustomerPoi: (id: string) => dispatch(propagateEditCustomerPoi(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const PoiDetailsContainer = connector(PoiDetails);
export default PoiDetailsContainer;
