import isNil from 'lodash/fp/isNil';

import { Coordinates, Poi, PoiType } from '../types';
import { Dispatch, Thunk } from '../../../types';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { appActions } from '../../app/reducer';

export const fetchPoi =
    (poiId: string): Thunk =>
        async (dispatch: Dispatch) => {
            try {
                dispatch(appActions.setFetchPoiRequested());

                const requestUrl = `${config.backend.POI_URL}/pois/${poiId}`;
                const poiDto = await fetchData<PoiDto>(requestUrl);
                dispatch(appActions.setFetchPoiSucceeded(mapPoi(poiDto as PoiDto)));
            } catch (e) {
                dispatch(appActions.setFetchPoiFailed());
            }
        };

const mapPoi = (poi: PoiDto): Poi => {
    switch (poi.type) {
        case PoiType.Customer:
            return poi as CustomerPoiDto;
        case PoiType.Workshop:
            const workshopPoiDto = poi as WorkshopPoiDto;
            return {
                id: workshopPoiDto.id,
                name: workshopPoiDto.name,
                type: workshopPoiDto.type,
                coordinates: workshopPoiDto.coordinates,
                address: mapAddress(workshopPoiDto.address),
                contact: workshopPoiDto.contact,
            };
    }
};

const mapAddress = (address?: WorkshopAddressDto) => {
    if (isNil(address) || isNil(address.zip) || isNil(address.zip) || isNil(address.city)) {
        return undefined;
    }

    return `${address.street}, ${address.zip} ${address.city} ${address.country}`;
};

interface CustomerPoiDto {
    id: string;
    name: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: string;
    category?: string;
}

type WorkshopAddressDto = {
    street?: string;
    zip?: string;
    city?: string;
    country: string;
};

interface WorkshopPoiDto {
    id: string;
    name?: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: WorkshopAddressDto;
    contact?: {
        email?: string;
        phone?: string;
    };
}

type PoiDto = CustomerPoiDto | WorkshopPoiDto;
