import './polyfills';

import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import { main, handleLoginRedirect, store } from './configuration';

import { config } from './config';
import AppContainer from './features/app/AppContainer';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { createRoot } from 'react-dom/client';

const renderApplication = () => {
    const container = document.getElementById('root');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'/*'} element={<AppContainer />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
