import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { sendMessage } from '../MessageHandler';

import { State } from '../../../types';
import { editCustomerPoiType } from '../../app/remoteActionTypes';

export const propagateEditCustomerPoi =
    (id: string): ThunkAction<void, State, unknown, Action<string>> =>
        (dispatch, getState) => {
            sendMessage({ type: editCustomerPoiType, payload: { id } });
        };
