import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import isEmpty from 'lodash/fp/isEmpty';

import { Dispatch, State } from '../../types';
import { useResizer } from './hooks/useResizer';
import { fetchPoi } from './fetchData/fetchPoi';
import { getSelectedPoiIds } from './selector';
import { appActions } from '../../features/app/reducer';
import { poisSelectedType, setVisibleWidgetsType, setWidgetIdType } from '../app/remoteActionTypes';


export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

const receiveMessage = (
    event: MessageEvent,
    setWidgetId: (widgetId: string) => void,
    setVisibleWidgets: (visibleWidgets: string[]) => void,
    setSelectedGeofences: (visibleGeofences: { ids: string[] }) => void) => {
    const actionType = event.data.type;
    if (!actionType) {
        return;
    }

    switch (actionType) {
        case setWidgetIdType:
            return setWidgetId(event.data.payload);
        case setVisibleWidgetsType:
            return setVisibleWidgets(event.data.payload);
        case poisSelectedType:
            return setSelectedGeofences(event.data.payload);

    }
};

export const MessageHandler = React.memo(({ selectedPoiIds, fetchPoiById, setWidgetId, setVisibleWidgets, setSelectedPois }: Props) => {
    useEffect(() => {
        const listenerCallback = (event: MessageEvent) => receiveMessage(event, setWidgetId, setVisibleWidgets, setSelectedPois);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResizer(sendMessage);

    useEffect(() => {
        if (!isEmpty(selectedPoiIds)) {
            fetchPoiById(selectedPoiIds[0]);
        }
    }, [selectedPoiIds, fetchPoiById]);

    return null;
});

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    selectedPoiIds: getSelectedPoiIds(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setWidgetId: (widgetId: string) => dispatch(appActions.setWidgetId(widgetId)),
    setVisibleWidgets: (visibleWidgets: string[]) => dispatch(appActions.setVisibleWidgets(visibleWidgets)),
    setSelectedPois: (poiIds: { ids: string[] }) => dispatch(appActions.setSelectedPois(poiIds)),
    fetchPoiById: (poiId: string) => dispatch(fetchPoi(poiId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const MessageHandlerContainer = connector(MessageHandler);
export default MessageHandlerContainer;
