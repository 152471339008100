export interface Coordinates {
    latitude: number;
    longitude: number;
}

export enum PoiType {
    Workshop = 'WORKSHOP',
    Customer = 'CUSTOMER',
}

export interface Poi {
    id: string;
    name?: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: string;

    // customer POI specific
    category?: string;

    // workshop POI specific
    contact?: {
        email?: string;
        phone?: string;
    };
}
