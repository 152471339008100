import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';
import { accessToken } from '../tokenHandling/accessToken';

const authorizeFetch = (fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>) => (url: string, options?: RequestInit) =>
    fetch(
        url,
        merge(cloneDeep(options), {
            headers: {
                Authorization: `Bearer ${accessToken.getAccessToken()}`,
                'Content-Type': 'application/json',
            },
        })
    );

export const fetchData = async <T>(url: string, options?: RequestInit): Promise<T | Error> => {
    try {
        const response = await authorizeFetch(window.fetch)(url, options);

        if (response.ok) {
            return response.json().catch(error => new Error(`${response.status} Invalid payload: ${error.message}`)) as Promise<T>;
        }

        if (response.status === 401) {
            return Promise.reject(new Error(`${response.status} Unauthorized: ${response.statusText}`));
        }

        return Promise.reject(new Error(`${response.status} Backend error: ${response.statusText}`));
    } catch (error) {
        return Promise.reject(new Error(`Error fetching data from backend ${url} with ${options} due to ${error}`));
    }
};
