import isNil from 'lodash/fp/isNil';
import { useIntl } from 'react-intl';
import React from 'react';

interface PoiDetailsRowProps {
    labelIntlId: string;
    labelIcon?: string;
    text?: string | React.ReactNode;
    link?: string;
    buttonIcon?: string;
    onButtonClick?: () => void;
}

const PoiDetailsRow = (props: PoiDetailsRowProps) => {
    const { labelIcon, labelIntlId, text, link, buttonIcon, onButtonClick } = props;

    const intl = useIntl();
    const formatMessageFromId = (id: string) => intl.formatMessage({ id });

    if (isNil(text)) {
        return null;
    }

    return (
        <div
            className={`display-flex padding-10 border-style-solid border-width-1 border-color-light border-right-0
                border-top-0 border-left-0`}
        >
            <div className={'width-40pct text-color-dark margin-left-5 margin-right-5'}>
                {labelIcon && (
                    <span className={`rioglyph rioglyph-${labelIcon} margin-right-3`} data-testid={'LabelIcon'} />
                )}
                {formatMessageFromId(labelIntlId)}
            </div>
            <div className={'width-60pct display-flex margin-left-5 margin-right-5'}>
                <div className={'flex-1-1'}>
                    {link ? (
                        <a href={link} target={'parent'}>
                            {text}
                        </a>
                    ) : (
                        text
                    )}
                </div>
                {buttonIcon && (
                    <button
                        className={'btn btn-muted btn-xs btn-icon-only margin-left-5'}
                        onClick={onButtonClick}
                        data-testid={`Button ${buttonIcon}`}
                    >
                        <span className={`rioglyph rioglyph-${buttonIcon} text-size-16`} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PoiDetailsRow;
