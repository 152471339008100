import { combineReducers, Reducer } from 'redux';
import { configReducer, langReducer, loginReducer } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer'; // TODO make import from ./configuration work
import { appReducer } from './features/app/reducer';

export const rootReducer = (): Reducer =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
    });
