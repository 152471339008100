import { Poi } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    widgetId?: string;
    visible: boolean;
    sessionExpiredAcknowledged: boolean;
    fetchPoiRequested: boolean;
    fetchPoiFailed: boolean;
    selectedPoiIds: string[];
    poi: Poi | undefined;
}

export const initialState: AppState = {
    widgetId: undefined,
    visible: false,
    sessionExpiredAcknowledged: false,
    fetchPoiRequested: false,
    fetchPoiFailed: false,
    selectedPoiIds: [],
    poi: undefined,
};

type SetWidgetIdPayload = string;
type SetVisibleWidgetsPayload = string[];

interface SelectedPois {
    ids: string[];
}

const { reducer, actions } = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state: AppState) => {
            state.sessionExpiredAcknowledged = true;
        },
        setFetchPoiRequested: (state: AppState) => {
            state.fetchPoiRequested = true;
            state.fetchPoiFailed = false;
        },
        setFetchPoiSucceeded: (state, action: PayloadAction<Poi>) => {
            state.fetchPoiRequested = false;
            state.fetchPoiFailed = false;
            state.poi = action.payload;
        },
        setFetchPoiFailed: (state) => {
            state.fetchPoiRequested = false;
            state.fetchPoiFailed = true;
        },
        setWidgetId: (state: AppState, action: PayloadAction<SetWidgetIdPayload>) => {
            state.widgetId = action.payload;
        },
        setVisibleWidgets: (state: AppState, action: PayloadAction<SetVisibleWidgetsPayload>) => {
            state.visible = action.payload.includes(state.widgetId!!);
        },
        setSelectedPois: (state: AppState, action: PayloadAction<SelectedPois>) => {
            state.selectedPoiIds = action.payload.ids;
        },
    },
});
export const appActions = actions;
export const appReducer = reducer;
